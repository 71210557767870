export default{
    portal : {
        backgroundColor:'rgba(0,0,0,0.3)',
        position: 'fixed',
        top:'0px',
        left:'0px',
        width:'100%',
        height: '100%',
        zIndex:'9998',
    },
    portalItem : {
        //position: 'absolute',
        position: 'fixed',
        //width: '100%',
        //maxWidth: '900px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        //paddingTop: '2rem',
        padding: '1rem',
        margin: '0px',
        boxShadow: '5px 6px 3px #ebebeb',
        zIndex:'9999',
    },
    container :{
        border: '1px solid #ebebeb',
        marginTop: '0.5rem'
    },
    scontainer :{
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
        '&.close' : {
            display: 'none'
        },
        '&.open' : {
            display: 'block'
        }
    },
    item : {
        display: 'flex',
        padding: '0.5rem 0px',
        margin:'0.5rem 0px',
        //border: '1px solid #ebebeb',
        alignItems: 'center',
        flexWrap: 'wrap',
        '& .itemin' : {
            margin:'0px 0.5rem'
        }
    },
    sitem:{
        display: 'flex',
        padding: '0.5rem 0px',
        margin:'0.5rem 0px',
        border: '1px solid #ebebeb',
        alignItems: 'center',
        '& .sitemin' : {
            margin:'0px 0.5rem'
        }
    },
    top : {
        'svg' : {
            fontSize: '1.5rem',
            cursor: 'pointer'
        },
        textAlign: 'right'
    }
}