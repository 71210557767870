import React, {useLayoutEffect} from 'react';
import { Route, Redirect } from 'react-router-dom';
import DashboardNoAuthLayoutRoute from 'layout/dashboardNoAuthLayout';
import { ForgotPassword } from 'auth/controls/forgotPassword/forgotPassword';
import { ResetPassword } from 'auth/controls/resetPassword/resetPassword';
import { Signup } from 'auth/controls/signup/signup';
import { Login } from 'auth';
import { useStore } from '../store';

const MemberLayout = ({children}) => {
  const { dispatch } = useStore();

  useLayoutEffect(() => {
    dispatch({type:'SET_STATE', payload : {scrolled : true}});
    
    // eslint-disable-next-line
  }, []);

  return(
    <div>
      <DashboardNoAuthLayoutRoute path='/member/login' component={Login} />
      <DashboardNoAuthLayoutRoute path='/member/forgotPassword' component={ForgotPassword} />
      <DashboardNoAuthLayoutRoute path='/member/resetPassword' component={ResetPassword} />
      
      <DashboardNoAuthLayoutRoute path='/member/signup' component={Signup} />
      <Route path='/member' exact={true}>
        <Redirect to='/member/dashboard' />
      </Route>
    </div>
  )
};
export default MemberLayout;