// xs,  sm,  md,  lg,  xl,  xxl     <-- only sample
//import { mq } from "../../../../cssInJs"

// <    576  768  992  1200 1600
export function getPanelStyle() {
  return {
    trade : {
      //border: '1px solid red',
      position: 'absolute',
      width: '50%',
      height: '100%',
      top: '0px',
      cursor: 'pointer'
    }
  }
}