import React from 'react';
import { useAuth } from 'auth';
import { useRouter } from 'components';

export function Signup(props) {
  
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [companyName, setCompanyName] = React.useState("");
  const [branchSiteName, setBranchSiteName] = React.useState("");
  
  const [error, setError] = React.useState("");
  const [pending, setPending] = React.useState(false);
  const auth = useAuth();

  const router = useRouter();

  function submit(e) {
    setPending(true);
    //console.log('loggin', username, password)
    e.preventDefault();
    e.stopPropagation();

    auth.signup(email, password, name, phone, companyName, branchSiteName).then(user => {
      console.log(user)
      setPending(false);
      setError('')
      router.push('/trade')
    }).catch(error => {
      console.log(error)
      setPending(false);
      setError(error.message)
    });
  }
  return (
    <div className="sign-in auth-panel"  style={{maxWidth: '600px', width:'90%', margin: 'auto'}}>
      <h1> Sign Up </h1>
      <p style={{fontSize: '0.9rem'}}>
        Please be aware that you will need a valid credit account with BSA Group, before we can process your trade order. <br />
        Any trade orders placed without a valid credit account will be treated as COD payment terms.
      </p>
      <h3> Trade Applications </h3>
      <p style={{fontSize: '0.9rem'}}>
        <strong> SA/WA/NT/QLD </strong> - <a href="http://bsagroup.applyeasy.com.au/credit" target="_blank" rel="noopener noreferrer">  <strong>  Apply Here </strong>  </a> <br />
        <strong> VIC/NSW </strong> - Fill out the <a href="/sites/batteryspecialties/media/pdfs/bsv-trade-credit-application-june-2020.pdf" download> <strong> Application Form </strong> </a> and return to <a href="mailto:accountsvic@bsag.com.au"> <strong> accountsvic@bsag.com.au </strong> </a>
      </p>
      <div className="loading-panel-holder" >
        {/* <app-loading [loadingStatus]="loadingStatus"></app-loading> */}
        <div className="panel-content">
          <form method="POST" noValidate="" onSubmit={submit}>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                id="email"
                type="email"
                className="form-control"
                name="email"
                required
                autoFocus
                autoComplete = "false"
                onChange={event => {
                  setEmail(event.target.value);
                }} />
              <div className="invalid-feedback">
                Email is invalid
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                id="password"
                type="password"
                className="form-control"
                name="password"
                required
                autoComplete = "false"
                onChange={event => {
                  setPassword(event.target.value);
                }} />
              <div className="invalid-feedback">
                Password is required
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="name"> Name </label>
              <input
                id="name"
                type="text"
                className="form-control"
                name="name"
                required
                autoFocus
                autoComplete = "false"
                onChange={event => {
                  setName(event.target.value);
                }} />
              <div className="invalid-feedback">
                Name is invalid
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="phone"> Phone </label>
              <input
                id="phone"
                type="text"
                className="form-control"
                name="phone"
                required
                autoFocus
                autoComplete = "false"
                onChange={event => {
                  setPhone(event.target.value);
                }} />
              <div className="invalid-feedback">
                Phone is invalid
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="companyName"> Company Name </label>
              <input
                id="companyName"
                type="text"
                className="form-control"
                name="companyName"
                required
                autoFocus
                autoComplete = "false"
                onChange={event => {
                  setCompanyName(event.target.value);
                }} />
              <div className="invalid-feedback">
                Company Name is invalid
              </div>
            </div>
            
            <div className="form-group">
              <label htmlFor="branchSiteName"> Branch or site name (if applicable) </label>
              <input
                id="branchSiteName"
                type="text"
                className="form-control"
                name="branchSiteName"
                autoFocus
                autoComplete = "false"
                onChange={event => {
                  setBranchSiteName(event.target.value);
                }} />
              <div className="invalid-feedback">
                Branch or site name is invalid
              </div>
            </div>

            <div className="form-check" style={{marginBottom: '0.5rem'}}>
              <input type="checkbox" className="signup form-check-input" id="checkbsagroup" required />
              <label className="form-check-label" htmlFor="checkbsagroup">
                I have an existing credit account with BSA Group
              </label>
            </div>


            <div className="form-group m-0">
              <button type="submit" disabled={pending} className="btn btn-primary btn-block">
                {pending && <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
                <span className=""> Sign Up </span>
              </button>
            </div>
            {error && <div style={{color: 'red'}}>{error}</div>}
          </form>
        </div>
      </div>
    </div>)
}