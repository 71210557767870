/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './searchButtonStyle';
import { FaSearch } from 'react-icons/fa';
import { useState, Fragment } from 'react';
import { useRouter } from '../../components';

export function SearchButton(){
    const [info, setInfo] = useState({
        openInput : false
    });

    const [keyword, setKeyword ] = useState('')
    const { navigate } = useRouter();

    function search(e) {
        e.preventDefault();
        setInfo({...info, openInput : false});
        setKeyword('');
        //onCloseMenu();
        navigate('/search?q=' + encodeURIComponent(keyword))
    }
    
    function onChange(e) {
        setKeyword(e.target.value);
    }

    return(
        <Fragment>
        <div css={style.container} className="search-button">
            <div style={{position: 'relative'}}>
                <div css={style.flexContainer} className={`${info.openInput && 'open'}`}>
                    <form css={style.form} className={`${info.openInput && 'open'}`} onSubmit={(e) => search(e)}>
                        <input css={style.input} type="text" placeholder="SEARCH" value={keyword} onChange={onChange} />
                    </form>
                    <div css={style.buttonContainer}>
                        <FaSearch onClick={() => {
                            setInfo({...info, openInput : !info.openInput});
                        }} />
                    </div>
                </div>
                <form css={style.formForMobile} className={`${info.openInput && 'open'}`} onSubmit={(e) => search(e)}>
                    <input css={style.input} type="text" placeholder="SEARCH" value={keyword} onChange={onChange} />
                </form>
            </div>
        </div>
        </Fragment>
    )
}