/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './popupMsgStyle';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Fragment } from 'react';
import { SiteLink } from 'components';

export function PopupMsg({closePopUp}){    
    return(
        <Fragment>
            <div css={style.portal} onClick={closePopUp}></div>
            <div css = {style.result}>
                <div css = {style.top}>
                    <AiOutlineCloseCircle onClick={closePopUp} />
                </div>
                <h2> Please login </h2>
                <div style={{ marginTop: 20 }}>
                    <SiteLink to={'/member/login'}> Login </SiteLink>
                </div>
            </div>
        </Fragment>
    )
}