import utils from "../utils";
import env from '../env';
import { isMin } from '../lib/css-emotion'

export const isMobile = () => {
  // isMin.md() ==> min-width: 768px
  return !isMin.md()
}

export function resourcePath(url){
  if (!url) return null;
  if (url && url.indexOf('/assets/') === 0) return url;
  return utils.url.isExternalLink(url)? url : env.resourceBase + url;
}

export function fullUrl(url){
  return utils.url.isExternalLink(url)? url : env.baseOrigin + url;
}


export const isNZ = env.locale === 'nz'

const lsVer = "V1";
const lsProductHistory = "lsProductHistory" + lsVer;

function createLsSearchInfo(){
  const lsProductHistoryData = {
    bannerImg : ''
  };
  localStorage.setItem(lsProductHistory, JSON.stringify(lsProductHistoryData));
}

export function setupLsPhImg(imgUrl){
  if(!localStorage.getItem(lsProductHistory)){
    createLsSearchInfo();
  }
  const lsProductHistoryData = JSON.parse(localStorage.getItem(lsProductHistory));
  lsProductHistoryData.bannerImg = imgUrl;
  localStorage.setItem(lsProductHistory, JSON.stringify(lsProductHistoryData));
}