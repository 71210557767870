/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useMemo, useState, useEffect, useLayoutEffect } from 'react';
import { Table } from 'components/table/table';
import style from './purchaseOrderViewStyle';
import utils from 'utils';
import { createPortal } from "react-dom";

function Portal({children}){
    const [el] = useState(document.createElement('div'));
    useEffect(() => {
        let mount = document.getElementById("portal-root");
        mount.appendChild(el);
      return () => mount.removeChild(el);
    }, [el]);
    return createPortal(children, el);
}

export function PurchaseOrderView({products, changeProductQty, categories, order, isMobile, checkAuth, history, currentInfo, addCartOrder}){

    console.log("Start PurchaseOrderView", currentInfo);
    console.log("products ------------------- ", products, categories, isMobile, checkAuth);

    const [openPopUp, setOpenPopUp] = useState({status : false, item : null});

    const handChange = ((el) => {
        const elName = el.target.name;
        const elValue = el.target.value;     //? el.target.value : 0;
        changeProductQty({partNumber : elName, qty : elValue});
    });

    const blurChange = ((el) => {
        const elName = el.target.name;
        const elValue = el.target.value;
        if(elValue === ""){
            changeProductQty({partNumber : elName, qty : "0"});
        }
    });

    const scrollToCategory = ((categoryName) => {
        const _items = document.querySelectorAll("div.category-in-table[data-category-code='" + categoryName + "']");
        const _vadfdfd = document.querySelector("#vadfdfd");
        const _table = document.querySelector("#poViewTable table.tableList");
        if(_items.length > 0){ 
            
            _table.scrollTop -= 10;
            
            _items[0].scrollIntoView({
                behavior: "auto", block: "start"
            }); 

            const y = _vadfdfd.getBoundingClientRect().top + window.pageYOffset + -100;
            setTimeout(() => {
                window.scrollTo({top:y, behavior:'smooth'});    
            }, 500);
        }
    });

    const getBrandLogo = ((brandName) => {
        let logoImg = "/assets/logos/logo.png";
        switch(brandName){
            case 'DURACELL' :
                logoImg = "/assets/brand/duracell-logo.jpg"
                break;
            case 'PROCELL' :
                logoImg = "/assets/brand/procell.jpg"
                break;
            case 'ENERGIZER' :
                logoImg = "/assets/brand/energizer-logo.jpg"
                break;
            case 'CENTURY' :
                logoImg = "/assets/brand/century-logo.jpg"
                break;
            case 'YUASA' :
                logoImg = "/assets/brand/yuasa.jpg"
                break;
            case 'EMERGENCY LIGHTING' :
                logoImg = "/assets/logos/logo.png"
                break;
            case 'EATON' :
                logoImg = "/assets/brand/eaton-logo.jpg"
                break;
            case 'RAITAR' :
                logoImg = "/assets/logos/logo.png"
                break;
            case 'SONNENSCHEIN' :
                logoImg = "/assets/brand/sonnenschein.jpg"
                break;
            case 'SAFT' :
                logoImg = "/assets/brand/saft.jpg"
                break;
            case 'ODYSSEY' :
                logoImg = "/assets/logos/logo.png"
                break;
            case 'VICTRON' :
                logoImg = "/assets/logos/logo.png"
                break;
            case 'BAE' :
                logoImg = "/assets/logos/logo.png"
                break;
            case 'FULLRIVER' :
                logoImg = "/assets/logos/logo.png"
                break;
            case 'VISON' :
                logoImg = "/assets/logos/logo.png"
                break;
            default :
                break;
        }
        return utils.css.bgUrlStyle(logoImg);
    });

    const columns = useMemo(() => [
        {
            Header: '',
            accessor: 'image',
            Cell : (c) => {
                return(
                    // <img css={style.img} src = "/assets/logos/logo.png" alt = "product" />
                    <div css={style.img} style={{backgroundImage:getBrandLogo(c.row.original.brand)}}></div>
                )
            }
        },
        {
            Header: 'Part Number',
            Cell : (c) => {
                return(
                    <div className={`category-in-table`} data-category-code={c.row.original.categoryCode}> {c.row.original.partNumber} </div>  
                )
            }
            //accessor: 'partNumber'
        },
        {
            Header: 'Category',
            Cell : (c) => {
                return(
                    c.row.original.categoryCode === 'no' ? '' : 
                    <div> {c.row.original.category} </div>
                )
            }
        },
        {
            Header: 'Description',
            accessor: 'description'
        },
        {
            Header: 'Voltage',
            accessor: 'voltage'
        },
        {
            Header: 'Capacity',
            accessor: 'capacity'
        },
        {
            Header: 'QTY',
            accessor: '',
            Cell : (c) => {
                return( 
                    <div css={style.input} className="form-group">
                        <input type="number" min="0" className="form-control form-control-sm" onBlur = {blurChange} onChange = { handChange } name = {c.row.original.partNumber} value={ products[c.row.index].qty } />
                    </div>
                )
            }
        },
        
        // eslint-disable-next-line
    ], []);

    const columnsMobile = useMemo(() => [
        // {
        //     Header: '',
        //     accessor: 'image',
        //     Cell : (c) => {
        //         return(
        //             <img css={style.img} src = "/assets/logos/logo.png" alt = "product" />
        //         )
        //     }
        // },
        {
            Header: 'Part Number',
            accessor: 'partNumber',
            Cell : (c) => {
                return(
                    <div css = {style.partNumberForMobile} onClick = {() => openPopUpFunction(products[c.row.index])} className={`category-in-table`} data-category-code={c.row.original.categoryCode}>
                        { c.row.original.partNumber }
                    </div>
                )
            }
        },
        {
            Header: 'Category',
            Cell : (c) => {
                return(
                    c.row.original.categoryCode === 'no' ? '' : 
                    <div> {c.row.original.category} </div>
                )
            }
        },
        {
            Header: 'QTY',
            accessor: '',
            Cell : (c) => {
                return( 
                    <div css={style.input} className="form-group">
                        <input type="number" className="form-control form-control-sm" min = "0" onChange = { handChange } name = {c.row.original.partNumber} value={ products[c.row.index].qty } />
                    </div>
                )
            }
        },
        
        // eslint-disable-next-line
    ], []);

    useLayoutEffect(() => {
        if(currentInfo !== ''){
            utils.ui.scrollTo("poViewTable");
        }

        // eslint-disable-next-line
    }, []);

    // const _categories = !categories.brandCategories  ? null : categories.brandCategories.map((item, index) =>
    //     <span css={style.category} key = {index} onClick={() => { scrollToCategory(item.categoryCode) }}> { item.categoryName } </span>
    // );
    
    const _categories = !categories.brandCategories || (categories.brandCategories && categories.brandCategories.length <=0) ? null : categories.brandCategories.map((item, index) =>
        <span css={style.category} key = {index} onClick={() => { scrollToCategory(item.categoryCode) }}> { item.categoryName } </span>
    );

    const openPopUpFunction = ((item) => {
        setOpenPopUp({status : true, item : item});
        document.body.style.overflow = "hidden";
    });

    const closePopUpFunction = (() => {
        setOpenPopUp({status : false, item : null});
        document.body.style.overflow = "unset";
    });

    return(
        <Fragment>
        {
            openPopUp.status &&
            <Portal>
                <div css = {style.portal} onClick={closePopUpFunction}></div>
                <div css = {style.result}>
                    <img css={style.popupImg} src = "/assets/logos/logo.png" alt = "product" />
                    <div className="row" css = {style.popupRow}>
                        <div className = 'col-12' css = {style.popupCol}>
                            {'Part Number : ' +  openPopUp.item.partNumber}
                        </div>
                        <div className = 'col-12' css = {style.popupCol}>
                            {'Brand : ' + openPopUp.item.brand}
                        </div>
                        <div className = 'col-12' css = {style.popupCol}>
                            {'Category : ' +  (openPopUp.item.category === 'NO' ? '' : openPopUp.item.category)}
                        </div>
                        <div className = 'col-12' css = {style.popupCol}>
                            {'Des : ' +  openPopUp.item.description}
                        </div>
                        <div className = 'col-6' css = {style.popupCol}>
                            {'Voltage : ' +  openPopUp.item.voltage}
                        </div>
                        <div className = 'col-6' css = {style.popupCol}>
                            {'Capacity : ' +  openPopUp.item.capacity}
                        </div>
                    </div>
                </div>
            </Portal>
        }
        <div css={style.container} id = "poViewTable" className={`${isMobile ? "isMobile" : ""}`}>
            <div css = {style.top} className="row" style={{marginBottom: '0.5rem', marginRight: '0', marginLeft: '0'}}> 
                {
                    _categories &&
                    <div className="col-12 category-container" css={style.categoryContainer} id="vadfdfd">
                        <span css={style.category} style={{cursor: 'auto'}}>
                            Categories:
                        </span>
                        {_categories}
                    </div>
                }
                
                <div className="col-5">
                    <h3 css={style.title}>
                        { currentInfo }
                    </h3>
                </div>
                
                <div className={_categories ? 'col-7' : 'col-7'} css = {style.buttonContainer}>
                    {/* {
                        checkAuth &&
                        <button style={{marginTop: '0.2rem', marginRight:'1rem'}} className = "btn btn-primary" onClick={history}> ORDER HISOTRY </button>
                    }
                    <button style={{marginTop: '0.2rem'}} className = "btn btn-primary" onClick={order}> ORDER </button> */}
                    <button css = {style.btn} style={{marginTop: '0.2rem'}} className = "btn btn-primary" onClick={order}> MY ORDER </button>
                    {
                        checkAuth &&
                        <button css = {style.btn} style={{marginTop: '0.2rem'}} className = "btn btn-primary" onClick={history}> ORDER HISTORY </button>
                    }
                    {
                        products.length > 0 &&
                        <button css = {style.btn} style={{marginTop: '0.2rem'}} className = "btn btn-primary" onClick={addCartOrder}> ADD TO ORDER </button>
                    }
                </div>
            </div>
            {
                products.length < 1 ?
                <div css={style.container} style={{textAlign: 'center'}}>
                    { (currentInfo && currentInfo !== '') && 'No Products '} 
                </div> : 
                <div css = {style.tableContainer}>
                    <Table columns={ isMobile ? columnsMobile : columns } data={products} />
                </div>
            } 
            {
                products.length > 0 &&
                <div style={{marginTop: '1rem', textAlign: 'right', paddingRight: '15px'}}>
                    <button css = {style.btn} style={{marginTop: '0.2rem'}} className = "btn btn-primary" onClick={addCartOrder}> ADD TO ORDER </button>
                </div>
            }
        </div>
        </Fragment>
    )
}