import React from 'react';
import { SiteLink } from 'components';
import env from 'env';

export function AppSideBar() {
  return (
    <div className="appSideBar">
      <div className="appSideBar__scroll">
        <section className="appSideBar__section">
        <h5>Ecommerce</h5>
        <ul className="appSideBar__group">
          <li>
            <SiteLink to="/admin/orders">Orders</SiteLink>
          </li>
          <li>
            <SiteLink to="/admin/products">Products</SiteLink>
          </li>
          <li>
            <SiteLink to="/admin/categories">Categories</SiteLink>
          </li>
        </ul>
          <h5>CMS</h5>
          <ul className="appSideBar__group">
            <li>
              {/* <SiteLink to={`${env.cmsEndpoint}`}> CMS (external)</SiteLink> */}
              <a href={`${env.cmsEndpoint}`} > CMS (external) </a>
            </li>
          </ul>

          <h5>Tools</h5>
          <ul className="appSideBar__group">
            <li>
              <SiteLink to="/admin/reindex">Re-index (search engine)</SiteLink>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}