/** @jsx jsx */
import { jsx } from '@emotion/core';
import {useEffect, useState, useMemo, Fragment } from 'react';
import style from './purchaseOrderStyle';
import utils from '../../../../utils';
//import cmsUtils from '../../cmsUtils';
import { useAuth } from 'auth';
import { Loading } from 'components';
//import { Redirect } from 'react-router-dom';
import { BrandList } from '../brandList/brandList';
import { PurchaseOrderView } from '../purchaseOrderView/purchaseOrderView';
import { OrderView } from '../orderView/orderView';
import { HistoryView } from '../historyView/historyView';
import { PopupMsg } from '../popupMsg/popupMsg';
import { AddCartPopUp } from '../addCartPopUp/addCartPopUp';
import { ErrorMessage } from '../errorMessage/errorMessage';
import { createPortal } from "react-dom";
import config from 'config';
import env from 'env';

function Portal({children}){
    const [el] = useState(document.createElement('div'));
    useEffect(() => {
        let mount = document.getElementById("portal-root");
        mount.appendChild(el);
      return () => mount.removeChild(el);
    }, [el]);
    return createPortal(children, el);
}

// const initialBrands = [
//     {name : 'DURACELL', totalQty : 0, logoImage : '/assets/brand/duracell-logo.jpg'},
//     {name : 'PROCELL', totalQty : 0, logoImage : '/assets/brand/procell.jpg' },
//     {name : 'ENERGIZER', totalQty : 0, logoImage : '/assets/brand/energizer-logo.jpg'},
//     {name : 'CENTURY', totalQty : 0, categories : [{name : 'AGM', code : 'agm'}, {name : 'VRLA', code : 'vrla'}], logoImage : '/assets/brand/century-logo.jpg'},
//     {name : 'YUASA', totalQty : 0, categories : [{name : 'AGM', code : 'agm'}, {name : 'VRLA', code : 'vrla'}], logoImage : '/assets/brand/yuasa.jpg'},
//     {name : 'EMERGENCY LIGHTING', totalQty : 0, categories : [{name : 'LIFEPO4', code : 'lifepo4'}, {name : 'NICD', code : 'nicd'}], logoImage : '/assets/logos/logo.png'},
//     {name : 'EATON', totalQty : 0, categories : [
//         {name : '1MBS', code : '1mbs'},
//         {name : 'DC POWER (AU)', code : 'dc-power-au'},
//         {name : 'DC RACKS & ePDU', code : 'dc-racks-epdu'},
//         {name : 'LINE INTERACTIVE', code : 'line-interactive'},
//         {name : 'ONLINE - SMALL', code : 'online-small'},
//         {name : 'SOFTWARE & CONNECTIVITY', code : 'software-connectivity'},
//         {name : 'SPD', code : 'spd'},
//         {name : 'STANDBY AVR', code : 'standby-avr'}
//     ], logoImage : '/assets/brand/eaton-logo.jpg'},
//     {name : 'RAITAR', totalQty : 0, logoImage : '/assets/logos/logo.png'},
//     {name : 'SONNENSCHEIN', totalQty : 0, logoImage : '/assets/brand/sonnenschein.jpg'},
//     {name : 'SAFT', totalQty : 0, logoImage : '/assets/brand/saft.jpg'},
//     {name : 'ODYSSEY', totalQty : 0, logoImage : '/assets/logos/logo.png'},
//     {name : 'VICTRON', totalQty : 0, logoImage : '/assets/logos/logo.png'},
//     {name : 'BAE', totalQty : 0, logoImage : '/assets/logos/logo.png'},
//     {name : 'FULLRIVER', totalQty : 0, logoImage : '/assets/logos/logo.png'},
//     {name : 'VISON', totalQty : 0, logoImage : '/assets/logos/logo.png'}
// ];

export function PurchaseOrder(props){

    console.log("PurchaseOrder start");
    
    const auth = useAuth();
    const item = props.item;

    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'purchaseOrder', item.cssClass || item.anchorName || '');
    const [purchaseOrderState, setPurchaseOrderState] = useState({
        pagePending : true,
        checkAuth : false,
        firstRunCheck : false,
        currentInfo : '',
        // currentProduts : null,
        // currentCategories : null,
        currentProduts : [],
        currentCartProducts : [],
        currentCategories : {},
        savedProducts : [],
        //savedProductsFiltered : [],
        isMobile : false,
        triggerConfirmOrder : 0,
        //brands : JSON.parse(JSON.stringify(initialBrands)),
        brands : [],
        backUpBrands : null,
        errors : [],
        orderConfirm : false,
        reOrder : false,
        location : '',
        purchaseOrderNum : '',
        quoteRequested : '',
        comments : ''
    });

    console.log("purchaseOrderState ::: ", purchaseOrderState);

    const [openPopUp, setOpenPopUp] = useState(false);
    const [popUpItem, setPopUpItem] = useState("order");

    useEffect(() => {
        console.log("PurchaseOrder - useEffect1");
        if(cmsOption.isCmsEdit){
            return;
        }
        
        //let _errors = [{ errorMessage : 'abgsdfsd'}];
        let _errors = [];

        const checkAuth = async() => { 
            let _checkAuth = purchaseOrderState.checkAuth;
            if(await auth.loggedIn()){
                _checkAuth = true;
            }

            let _isMobile = false;
            if(window.innerWidth <= 600){
                _isMobile = true;
            }

            const fetchData = async()=>{

                const res = await fetch(env.apiBase + '/api/purchaseorder/getbrands');
                const data = await res.json();
                console.log("data :::: ", data);

                let _brands = [];

                if(data && data.results && data.results.brands){
                    _brands = data.results.brands;
                }
                
                setPurchaseOrderState({
                    ...purchaseOrderState,
                    pagePending : false,
                    checkAuth : _checkAuth,
                    firstRunCheck : true,
                    isMobile : _isMobile,
                    errors : _errors,
                    brands : _brands,
                    backUpBrands : JSON.parse(JSON.stringify(_brands)),
                });
            };
            fetchData();
        }

        const timer = setTimeout(() => {
            checkAuth();
        }, 1000);

        return () => {
            clearTimeout(timer);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        console.log("PurchaseOrder - useEffect2");
        if(!purchaseOrderState.currentInfo){
            return;
        }
        let _errors = [];
        let _currentProduts = null;
        const fetchData = async() => {
            try{                
                const res = await fetch(env.apiBase + '/api/purchaseorder/GetPurchaseOrderItemsByBrandName/' + purchaseOrderState.currentInfo);
                const data = await res.json();
                console.log("data ------------ ", data);

                if(data.results.poProducts){
                    _currentProduts = [];
                    data.results.poProducts.forEach(el => {
                        _currentProduts.push({...el, qty : '0' });
                    });
                }

                if(data.errors.length > 0){
                    _errors = data.errors;
                }
            }
            catch(ex){}

            let _indexForBrand = findIndexForBrand();
            const _currentCategories = purchaseOrderState.brands[_indexForBrand];

            setPurchaseOrderState({
                ...purchaseOrderState,
                pagePending : false,
                errors : _errors,
                currentProduts : _currentProduts,
                currentCategories : _currentCategories,
                currentCartProducts : []
            });
        };
        fetchData();
        // eslint-disable-next-line
    }, [purchaseOrderState.currentInfo]);

    useEffect(() => {
        console.log("PurchaseOrder - useEffect3");
        if(cmsOption.isCmsEdit || purchaseOrderState.triggerConfirmOrder === 0){
            return;
        }
        let _errors = [];
        let _orderConfirm = true;

        const fetchData = async() => {
            try{  
                const _options = { headers: { 'Content-Type': 'application/json' } };
                await config.apiNetJwt(_options);
                const res = await fetch(env.apiBase + '/api/purchaseorder/ConfirmOrder', {
                    method : 'post',
                    //headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        items : purchaseOrderState.savedProducts,
                        reOrder : purchaseOrderState.reOrder,
                        location : purchaseOrderState.location,
                        purchaseOrderNum : purchaseOrderState.purchaseOrderNum,
                        quoteRequested : purchaseOrderState.quoteRequested,
                        comments : purchaseOrderState.comments
                    }),
                    ..._options,
                });
                const data = await res.json();
                if(data.errors.length > 0){
                    _errors = data.errors;
                    _orderConfirm = false;
                }
            }
            catch(ex){}

            let _brands = JSON.parse(JSON.stringify(purchaseOrderState.backUpBrands));

            //reset
            setPurchaseOrderState({
                ...purchaseOrderState,
                pagePending : false,
                currentInfo : '',
                // currentProduts : null,
                // currentCategories : null,
                currentProduts : [],
                currentCategories : {},
                currentCartProducts : [],
                savedProducts : [],
                //savedProductsFiltered : [],
                //brands : JSON.parse(JSON.stringify(initialBrands)),
                brands : _brands,
                errors : _errors,
                orderConfirm : _orderConfirm,
                reOrder : false,
                location : '',
                purchaseOrderNum : '',
                quoteRequested : '',
                comments : ''
            });
            utils.ui.scrollTo("purchaseorderpanelmsg");
        }
        fetchData();
        // eslint-disable-next-line
    }, [purchaseOrderState.triggerConfirmOrder]);

    const changeCurrnetProduct = ((value) => {
        
        setPurchaseOrderState({
            ...purchaseOrderState,
            currentInfo : value,
            pagePending : true,
            orderConfirm : false
        });
    });

    const changeProductQty = ((obj) => {
        setPurchaseOrderState(pre => {
            let _new = {...pre};
            let _qty = obj.qty;
            let _index = _new.currentProduts.findIndex(x => x.partNumber === obj.partNumber);
            if(_qty.length >=2 && _qty.charAt(0) === "0"){
                _qty = _qty.replace("0", "");
            }
            _new.currentProduts[_index].qty = _qty;
            let _indeCurrentCartProduct = _new.currentCartProducts.findIndex(x => x.partNumber === obj.partNumber);
            
            if(_indeCurrentCartProduct === -1){
                if(_new.currentProduts[_index].qty !== '0'){
                    _new.currentCartProducts.push(_new.currentProduts[_index]);
                }
            }
            else{
                if(_new.currentProduts[_index].qty === '0'){
                    _new.currentCartProducts.splice(_indeCurrentCartProduct, 1);
                }
            }
            return _new;
        });

    });

    const removeSavedProduct = ((index) => {
        purchaseOrderState.savedProducts.splice(index, 1);
        setPurchaseOrderState({...purchaseOrderState});
    });
    
    const changeSavedProductQty = ((obj) => {

        setPurchaseOrderState(pre => {
            let _new = {...pre};
            
            let _qty = obj.qty;
            let _index = _new.savedProducts.findIndex(x => x.partNumber === obj.partNumber);
            if(_qty.length >=2 && _qty.charAt(0) === "0"){
                _qty = _qty.replace("0", "");
            }
            _new.savedProducts[_index].qty = _qty;
            return _new;
        });
    });

    const findIndexForSavdProduct = ((partNumber) => {
        let index = -1;
        index = purchaseOrderState.savedProducts.findIndex(x => x.partNumber === partNumber);
        return index;
    });

    // const findIndexForCurrentCartProduct = ((partNumber) => {
    //     let index = -1;
    //     index = purchaseOrderState.currentCartProducts.findIndex(x => x.partNumber === partNumber);
    //     return index;
    // });

    const findIndexForBrand = (() => {
        let index = -1;
        index = purchaseOrderState.brands.findIndex(x => x.brandName === purchaseOrderState.currentInfo);
        return index;
    });

    const addCartOrder = (() => {
        setPopUpItem('addcart');
        setOpenPopUp(true);
        document.body.style.overflow = "hidden";
    });

    const order = (() => {
        setOpenPopUp(true);
        document.body.style.overflow = "hidden";
    });

    const history = (() => {
        setPopUpItem('history');
        setOpenPopUp(true);
        document.body.style.overflow = "hidden";
    });

    const confirmCartOreder = (() => {
        closePopUp();

        purchaseOrderState.currentCartProducts.forEach(el => {
            const copiedEl = JSON.parse(JSON.stringify(el));
            const sIndex = findIndexForSavdProduct(copiedEl.partNumber);
            if(sIndex !== -1){
                purchaseOrderState.savedProducts[sIndex].qty = 
                    parseInt(purchaseOrderState.savedProducts[sIndex].qty) + parseInt(copiedEl.qty);
            }
            else{
                purchaseOrderState.savedProducts.push(copiedEl);
            }
        });
        
        purchaseOrderState.currentProduts.forEach(sEl=> {
            sEl.qty = "0";
        });
        
        purchaseOrderState.currentCartProducts = [];
        setPurchaseOrderState({...purchaseOrderState});
    });

    const confirmOrder = ((location, purchaseOrderNum, quoteRequested, comments) => {
        closePopUp();
        console.log('', location, purchaseOrderNum, quoteRequested, comments);
        utils.ui.scrollTo("purchaseorderpanel");
        setPurchaseOrderState({
            ...purchaseOrderState,
            pagePending : true,
            location : location,
            purchaseOrderNum : purchaseOrderNum,
            quoteRequested : quoteRequested,
            comments : comments,
            triggerConfirmOrder : purchaseOrderState.triggerConfirmOrder + 1
        });
    });

    const confirmReOrder = ((reOrderProcuts) => {
        closePopUp();
        console.log("reOrderProcuts", reOrderProcuts);
        setPurchaseOrderState({
            ...purchaseOrderState,
            pagePending : true,
            //savedProductsFiltered : reOrderProcuts.items,
            savedProducts : reOrderProcuts.items,
            reOrder : true,
            location : reOrderProcuts.location,
            purchaseOrderNum : reOrderProcuts.purchaseOrderNum,
            triggerConfirmOrder : purchaseOrderState.triggerConfirmOrder + 1
        });
    });

    const closePopUp = (() => {
        setOpenPopUp(false);
        setPopUpItem('order');
        document.body.style.overflow = "unset";
    });

    const brandList = useMemo(() => {
        return(
            <BrandList changeCurrnetProduct = {changeCurrnetProduct} currentInfo = {purchaseOrderState.currentInfo} brands = {purchaseOrderState.brands} ></BrandList>
        )
        // eslint-disable-next-line
    }, [purchaseOrderState]);

    const purchaseOrderView = useMemo(() => {
        return(
            <PurchaseOrderView
                products = {purchaseOrderState.currentProduts}
                changeProductQty = {changeProductQty}
                categories = {purchaseOrderState.currentCategories}
                order = {order}
                isMobile = {purchaseOrderState.isMobile}
                checkAuth = {purchaseOrderState.checkAuth}
                history = {history}
                currentInfo = {purchaseOrderState.currentInfo}
                addCartOrder = {addCartOrder}
            />
        )
        // eslint-disable-next-line
    }, [purchaseOrderState]);

    if (cmsOption.isCmsEdit){
        return(
            <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
                <div css={style.cmsEditMode} className='cms_edit_mode'>
                    Purchase Order Item
                </div>
            </div>
        )
    }

    return(
        <Fragment>
        {
            openPopUp &&
            <Portal>
                {
                    purchaseOrderState.checkAuth ?
                    <Fragment>
                        {
                            popUpItem === 'order' &&
                            <OrderView
                                confirmOrder = {confirmOrder}
                                closePopUp = {closePopUp}
                                savedProducts = {purchaseOrderState.savedProducts}
                                changeSavedProductQty = {changeSavedProductQty}
                                removeSavedProduct = {removeSavedProduct}>
                            </OrderView>
                        }
                        {
                            popUpItem === 'history' &&
                            <HistoryView closePopUp = {closePopUp} confirmReOrder = {confirmReOrder} />
                        }
                        {
                            popUpItem === 'addcart' &&
                            <AddCartPopUp closePopUp = {closePopUp} currentCartProducts={purchaseOrderState.currentCartProducts} confirmCartOreder={confirmCartOreder} />
                        }
                    </Fragment>
                     :
                    <PopupMsg closePopUp = {closePopUp}></PopupMsg>
                }
            </Portal>
        }
        <div className={cssClass} id="purchaseorderpanel">
            {
                purchaseOrderState.pagePending ? <Loading></Loading> :
                <div>
                    { brandList }
                    {
                        purchaseOrderState.currentProduts && purchaseOrderView  
                    }
                    <ErrorMessage errors={purchaseOrderState.errors} />
                    <div id="purchaseorderpanelmsg"></div>
                    {
                        purchaseOrderState.orderConfirm &&
                        <div style={{marginLeft: '1.5rem', color:'red', fontWeight: 'bold', textAlign:'center'}}> 
                            Successfully sent!
                        </div>
                    }
                </div>
            }
        </div>
        </Fragment>
    )
}