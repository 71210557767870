import React from 'react';
import { Route } from 'react-router-dom';
//import { ViewPartial } from '../cms/pages/partials/viewPartial';
//import { MenuLoader } from './menuLoader';

const DashboardLayout = ({ children, ...rest }) => {
  return <div className='pt-24 dashboard__layout'>{children}</div>;
};

const DashboardNoAuthLayoutRoute = ({
  component: RenderComponent,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <React.Fragment>
        <DashboardLayout>
          {/* <MenuLoader /> */}
          <RenderComponent {...matchProps} />
        </DashboardLayout>
        {/* <ViewPartial /> */}
        </React.Fragment>
      )}
    />
  );
};

export default DashboardNoAuthLayoutRoute;