/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState,Fragment } from 'react';
import env from 'env';
import { Loading } from 'components';

export function BatteryMatcherForm({cssStyle}){
    const s = cssStyle;
    const [currentStep, setCurrentStep] = useState("car");
    const [pending, setPending] = useState(false);

    useEffect(() => {
        
        if(pending === false){
            return;
        }
        
        setTimeout(() => {
            setPending(false);
        }, 1000);
        // eslint-disable-next-line
    }, [currentStep]);

    return(
        <div css={s} className="batteryMatcherForm">
            <ul>
                <li>
                    <button className={`btn btn-outline-primary btn-sm ${currentStep === 'car' ? 'selected' : ''}`} onClick={(e)=>{
                        setPending(true);
                        setCurrentStep('car');
                    }}>
                        Car Batteries
                    </button>
                </li>
                <li>
                    <button className={`btn btn-outline-primary btn-sm ${currentStep === 'marine' ? 'selected' : ''}`} onClick={(e)=>{
                        setPending(true);
                        setCurrentStep('marine');
                    }}>
                        Marine Batteries
                    </button>
                </li>
                <li>
                    <button className={`btn btn-outline-primary btn-sm ${currentStep === 'caravan' ? 'selected' : ''}`} onClick={(e)=>{
                        setPending(true);
                        setCurrentStep('caravan');
                    }}>
                        Caravan Batteries
                    </button>
                </li>
            </ul>

            {
                pending ? <Loading></Loading> :
                <Fragment>
                    {
                        currentStep === 'car' &&
                        <CarForm></CarForm>
                    }
                    {
                        currentStep === 'marine' &&
                        <MarineForm></MarineForm>
                    }
                    {
                        currentStep === 'caravan' &&
                        <CaravanForm></CaravanForm>
                    }
                </Fragment>
            }
        </div>
    )
}

function CarForm(){

    const [formValue, setFormValue] = useState({
        name : '',
        phoneNumber : '',
        email : '',
        location : '',
        make : '',
        model : '',
        year : '',
        cylinders : '',
        engineSize : '',
        terminalPositive : '',
        enquiry : '',
        emailReceiver : ''
    });

    const [pending, setPending] = useState(false);
    const [triggerForm, setTriggerForm] = useState(0);
    const [result, setResult] = useState({errors : [], results : null});

    function submit(e){
        e.preventDefault();
        e.stopPropagation();
        setPending(true);
        setResult({errors : [], results : null});
        setTriggerForm(triggerForm + 1);
    }

    function handChange(el){
        const elName = el.target.name;
        const elValue =el.target.value;
        setFormValue({...formValue, [elName] : elValue});
    }

    useEffect(() => {
        if(triggerForm === 0){
            return;
        }
        const fetchData = async() => {
            try{
                const res = await fetch(env.apiBase + '/api/form/batteryMatcherformcarsend', {
                    method : 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formValue)
                });
                const data = await res.json();
                console.log("data", data);
                setFormValue({
                    name : '',
                    phoneNumber : '',
                    email : '',
                    location : '',
                    make : '',
                    model : '',
                    year : '',
                    cylinders : '',
                    engineSize : '',
                    terminalPositive : '',
                    enquiry : '',
                    emailReceiver : ''
                });
                setResult({...result, errors : data.errors, results : "ok"});
            }
            catch(ex){

            }
            setPending(false);
        };
        fetchData();
        // eslint-disable-next-line
    }, [triggerForm]);

    return(
        <div className="cartForm">
            <form method='POST' onSubmit={submit}>
                <div className='form-group row'>
                    <label htmlFor='name' className="col-sm-3 col-form-label"> Name* </label>
                    <div className = "col-sm-9">
                        <input id='name' type='text'className='form-control' value={formValue.name} name='name' required autoComplete='false' onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Name is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='phoneNumber' className="col-sm-3 col-form-label"> Phone Number* </label>
                    <div className = "col-sm-9">
                        <input id='phoneNumber' type='text'className='form-control' value={formValue.phoneNumber} name='phoneNumber' required autoComplete='false' onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Phone number is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='email' className="col-sm-3 col-form-label"> Email* </label>
                    <div className = "col-sm-9">
                        <input id='email' type='email'className='form-control' value={formValue.email} name='email' required autoComplete='false' onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Email is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='location' className="col-sm-3 col-form-label"> Location* </label>
                    <div className = "col-sm-9">
                        <select id="location" name="location" className="form-control" value={formValue.location} required onChange={handChange}>
                            <option value=""> What state are you from?  </option>
                            <option value = "ACT"> Australian Captital Territory</option>
                            <option value = "New South Wales"> New South Wales </option>
                            <option value = "Victoria"> Victoria </option>
                            <option value = "South Australia"> South Australia </option>
                            <option value = "Queensland"> Queensland </option>
                            <option value = "Western Australia"> Western Australia </option>
                            <option value = "Northern Territory"> Northern Territory </option>
                            <option value = "Tasmania"> Tasmania </option>
                        </select>
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='make' className="col-sm-3 col-form-label"> Make* </label>
                    <div className = "col-sm-9">
                        <input id='make' type='text'className='form-control' value={formValue.make} name='make' required autoComplete='false' onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='model' className="col-sm-3 col-form-label"> Model* </label>
                    <div className = "col-sm-9">
                        <input id='model' type='text'className='form-control' value={formValue.model} name='model' required autoComplete='false' onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='year' className="col-sm-3 col-form-label"> Year* </label>
                    <div className = "col-sm-9">
                        <input id='year' type='text' className='form-control' value={formValue.year} name='year' required autoComplete='false' onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='cylinders' className="col-sm-3 col-form-label"> Cylinders* </label>
                    <div className = "col-sm-9">
                        <input id='cylinders' type='text' className='form-control' value={formValue.cylinders} name='cylinders' required autoComplete='false' onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='engineSize' className="col-sm-3 col-form-label"> Engine Size (L)* </label>
                    <div className = "col-sm-9">
                        <input id='engineSize' type='text' className='form-control' value={formValue.engineSize} name='engineSize' required autoComplete='false' onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='terminalPositive' className="col-sm-3 col-form-label"> Terminal positive R/L </label>
                    <div className = "col-sm-9">
                        <input id='terminalPositive' type='text' className='form-control' value={formValue.terminalPositive} name='terminalPositive' required autoComplete='false' onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='enquiry' className="col-sm-3 col-form-label"> Enquiry </label>
                    <div className = "col-sm-9">
                        <textarea  id='enquiry' className='form-control' name='enquiry' row = "3" value={formValue.enquiry} onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
                <div className='form-group row'>
                    <div className = "offset-sm-3 col-sm-9">
                        <button type="submit" disabled={pending} className="btn btn-outline-primary btn-sm">
                            SEND
                        </button>
                        { 
                            result.results && result.errors.length <=0 &&
                            <span style={{marginLeft: '1.5rem', color:'red', fontWeight: 'bold'}}> Successfully sent! </span>
                        }
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
          </form>
        </div>
    )
}

function MarineForm(){
    
    const [formValue, setFormValue] = useState({
        name : '',
        phoneNumber : '',
        email : '',
        location : '',
        searchOption : '',
        enquiry : '',
        emailReceiver : ''
    });

    const [pending, setPending] = useState(false);
    const [triggerForm, setTriggerForm] = useState(0);
    const [result, setResult] = useState({errors : [], results : null});

    function submit(e){
        e.preventDefault();
        e.stopPropagation();
        setPending(true);
        setResult({errors : [], results : null});
        setTriggerForm(triggerForm + 1);
    }

    function handChange(el){
        const elName = el.target.name;
        const elValue =el.target.value;
        setFormValue({...formValue, [elName] : elValue});
    }

    useEffect(() => {
        if(triggerForm === 0){
            return;
        }
        const fetchData = async() => {
            try{
                const res = await fetch(env.apiBase + '/api/form/batterymatcherformmarinesend', {
                    method : 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formValue)
                });
                const data = await res.json();
                console.log("data", data);
                setFormValue({
                    name : '',
                    phoneNumber : '',
                    email : '',
                    location : '',
                    searchOption : '',
                    enquiry : '',
                    emailReceiver : ''
                });
                setResult({...result, errors : data.errors, results : "ok"});
            }
            catch(ex){

            }
            setPending(false);
        };
        fetchData();
        // eslint-disable-next-line
    }, [triggerForm]);

    return(
        <div className="marineForm">
            <form method='POST' onSubmit={submit}>
                <div className='form-group row'>
                    <label htmlFor='name' className="col-sm-3 col-form-label"> Name* </label>
                    <div className = "col-sm-9">
                        <input id='name' type='text'className='form-control' value={formValue.name} name='name' required autoFocus autoComplete='false' onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Name is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='phoneNumber' className="col-sm-3 col-form-label"> Phone Number* </label>
                    <div className = "col-sm-9">
                        <input id='phoneNumber' type='text'className='form-control' value={formValue.phoneNumber} name='phoneNumber' required autoComplete='false' onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Phone number is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='email' className="col-sm-3 col-form-label"> Email* </label>
                    <div className = "col-sm-9">
                        <input id='email' type='email'className='form-control' value={formValue.email} name='email' required autoComplete='false' onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Email is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='location' className="col-sm-3 col-form-label"> Location* </label>
                    <div className = "col-sm-9">
                        <select id="location" name="location" className="form-control" value={formValue.location} required onChange={handChange}>
                            <option value=""> What state are you from?  </option>
                            <option value = "ACT"> Australian Captital Territory</option>
                            <option value = "New South Wales"> New South Wales </option>
                            <option value = "Victoria"> Victoria </option>
                            <option value = "South Australia"> South Australia </option>
                            <option value = "Queensland"> Queensland </option>
                            <option value = "Western Australia"> Western Australia </option>
                            <option value = "Northern Territory"> Northern Territory </option>
                            <option value = "Tasmania"> Tasmania </option>
                        </select>
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='searchOption' className="col-sm-3 col-form-label"> Search Option* </label>
                    <div className = "col-sm-9">
                        <select id="searchOption" name="searchOption" className="form-control" value={formValue.searchOption} required onChange={handChange}>
                            <option value=""> Select option below  </option>
                            <option value = "Start-Up"> Start-Up </option>
                            <option value = "aux"> aux </option>
                        </select>
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='enquiry' className="col-sm-3 col-form-label"> Enquiry </label>
                    <div className = "col-sm-9">
                        <textarea  id='enquiry' className='form-control' name='enquiry' row = "3" value={formValue.enquiry} onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
                <div className='form-group row'>
                    <div className = "offset-sm-3 col-sm-9">
                        <button type="submit" disabled={pending} className="btn btn-outline-primary btn-sm">
                            SEND
                        </button>
                        { 
                            result.results && result.errors.length <=0 &&
                            <span style={{marginLeft: '1.5rem', color:'red', fontWeight: 'bold'}}> Successfully sent! </span>
                        }
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
          </form>
        </div>
    )
}

function CaravanForm(){
    
    const [formValue, setFormValue] = useState({
        name : '',
        phoneNumber : '',
        email : '',
        location : '',
        searchOption : '',
        enquiry : '',
        emailReceiver : ''
    });

    const [pending, setPending] = useState(false);
    const [triggerForm, setTriggerForm] = useState(0);
    const [result, setResult] = useState({errors : [], results : null});

    function submit(e){
        e.preventDefault();
        e.stopPropagation();
        setPending(true);
        setResult({errors : [], results : null});
        setTriggerForm(triggerForm + 1);
    }

    function handChange(el){
        const elName = el.target.name;
        const elValue =el.target.value;
        setFormValue({...formValue, [elName] : elValue});
    }

    useEffect(() => {
        if(triggerForm === 0){
            return;
        }
        const fetchData = async() => {
            try{
                const res = await fetch(env.apiBase + '/api/form/batterymatcherformmarinesend', {
                    method : 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(formValue)
                });
                const data = await res.json();
                console.log("data", data);
                setFormValue({
                    name : '',
                    phoneNumber : '',
                    email : '',
                    location : '',
                    searchOption : '',
                    enquiry : '',
                    emailReceiver : ''
                });
                setResult({...result, errors : data.errors, results : "ok"});
            }
            catch(ex){

            }
            setPending(false);
        };
        fetchData();
        // eslint-disable-next-line
    }, [triggerForm]);

    return(
        <div className="caravanForm">
            <form method='POST' onSubmit={submit}>
                <div className='form-group row'>
                    <label htmlFor='name' className="col-sm-3 col-form-label"> Name* </label>
                    <div className = "col-sm-9">
                        <input id='name' type='text'className='form-control' value={formValue.name} name='name' required autoFocus autoComplete='false' onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Name is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='phoneNumber' className="col-sm-3 col-form-label"> Phone Number* </label>
                    <div className = "col-sm-9">
                        <input id='phoneNumber' type='text'className='form-control' value={formValue.phoneNumber} name='phoneNumber' required autoComplete='false' onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Phone number is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='email' className="col-sm-3 col-form-label"> Email* </label>
                    <div className = "col-sm-9">
                        <input id='email' type='email'className='form-control' value={formValue.email} name='email' required autoComplete='false' onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Email is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='location' className="col-sm-3 col-form-label"> Location* </label>
                    <div className = "col-sm-9">
                        <select id="location" name="location" className="form-control" value={formValue.location} required onChange={handChange}>
                            <option value=""> What state are you from?  </option>
                            <option value = "ACT"> Australian Captital Territory</option>
                            <option value = "New South Wales"> New South Wales </option>
                            <option value = "Victoria"> Victoria </option>
                            <option value = "South Australia"> South Australia </option>
                            <option value = "Queensland"> Queensland </option>
                            <option value = "Western Australia"> Western Australia </option>
                            <option value = "Northern Territory"> Northern Territory </option>
                            <option value = "Tasmania"> Tasmania </option>
                        </select>
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='searchOption' className="col-sm-3 col-form-label"> Search Option* </label>
                    <div className = "col-sm-9">
                        <select id="searchOption" name="searchOption" className="form-control" value={formValue.searchOption} required onChange={handChange}>
                            <option value=""> Select option below  </option>
                            <option value = "List Accessories"> List Accessories </option>
                            <option value = "Estimated Load"> Estimated Load </option>
                        </select>
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
                <div className='form-group row'>
                    <label htmlFor='enquiry' className="col-sm-3 col-form-label"> Enquiry </label>
                    <div className = "col-sm-9">
                        <textarea  id='enquiry' className='form-control' name='enquiry' row = "3" value={formValue.enquiry} onChange={handChange} />
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
                <div className='form-group row'>
                    <div className = "offset-sm-3 col-sm-9">
                        <button type="submit" disabled={pending} className="btn btn-outline-primary btn-sm">
                            SEND
                        </button>
                        { 
                            result.results && result.errors.length <=0 &&
                            <span style={{marginLeft: '1.5rem', color:'red', fontWeight: 'bold'}}> Successfully sent! </span>
                        }
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                </div>
          </form>
        </div>
    )
}