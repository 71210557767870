import { mq, variables } from 'cssInJs';

export default {
    img : mq({
        //width : ['45px', null, '60px']
        //paddingTop:'20px'
        width:'65px',
        height:'40px',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    }),

    popupImg : {
        width : '260px',
        display: 'block',
    },

    des : {
        maxWidth: '350px'
    },
    buttonContainer : {
        textAlign: 'right',
        //marginBottom: '0.5rem'
    },
    container : {
        //overflow: 'auto',
        position: 'relative',
        //maxHeight: '1000px',
        margin: '1.5rem 0',
        // '&.isMobile' : {
        //     '.category-container' : {
        //         marginBottom: '0.5rem'
        //     }
        // }
    },
    categoryContainer : {
        //border: '1px solid black',
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        marginBottom: '0.5rem'
    },
    category : {
        fontSize: '0.8rem',
        marginLeft: '0.3rem',
        marginRight: '0.3rem',
        display: 'inline-block',
        cursor: 'pointer',
        fontWeight:'600',
        color:variables.darkTealColor
    },
    input : {
        marginBottom: '0px'
    },
    top : {
        //position: 'sticky',
        //top:0,
        backgroundColor: 'white',
        //zIndex: '2'
    },
    partNumberForMobile : {
        color: variables.darkTealColor,
        cursor: 'pointer',
        fontWeight: 700
    },
    portal : {
        backgroundColor:'rgba(0,0,0,0.3)',
        position: 'fixed',
        top:'0px',
        left:'0px',
        width:'100%',
        height: '100%',
        zIndex:'9998',
    },
    result : {
        //position: 'absolute',
        position: 'fixed',
        //width: '100%',
        //maxWidth: '900px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        //paddingTop: '2rem',
        padding: '1rem',
        margin: '0px',
        boxShadow: '5px 6px 3px #ebebeb',
        zIndex:'9999',
    },
    popupRow : {
        marginTop: '1rem'
    },
    popupCol : {
        marginTop: '0.2rem',
        marginBottom: '0.2rem'
    },
    tableContainer : {
        overflow: 'auto',
        maxHeight: '1000px',
    },
    btn :{
        marginLeft:'0.2rem'
    },
    title : {
        marginTop:'0px',
        marginBottom: '0px',
        position: 'absolute',
        top:'50%',
        transform:'translateY(-50%)',
    }
}