import React, {useEffect, Fragment } from 'react';
import { useAuth } from 'auth';
import { Loading, useRouter, SiteLink } from 'components';

export function Login(props) {
  const [pagePending, setPagePending] = React.useState(true);
  const [checkAuth, setCheckAuth] = React.useState(false);
  const auth = useAuth();
  
  useEffect(() => {
    const checkAuth = async() => {
      if(await auth.loggedIn()){
        setCheckAuth(true);
      }
      setPagePending(false);
    }
    checkAuth();
    // eslint-disable-next-line
  }, []);

  return (
    <div className='sign-in auth-panel' style={{ maxWidth: '600px', width: '90%', margin: 'auto' }}>
      { 
        pagePending ? <Loading></Loading> : <LoginView checkAuth = {checkAuth} auth = {auth}></LoginView>
      }
    </div>
  );
}

function LoginView({checkAuth, auth}) {
  const [pending, setPending] = React.useState(false);
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');
  const router = useRouter();
  
  function submit(e) {
    
    setPending(true);
    e.preventDefault();
    e.stopPropagation();

    auth
      .signin(username, password)
      .then((user) => {
        //console.log("wwwwwwwwwww", user);
        setPending(false);
        setError('');
        //router.push('/member/dashboard');
        router.push('/trade');
      })
      .catch((error) => {
        console.log(error);
        setPending(false);
        setError(error.message);
      });
  }

  function signout(e) {
    e.preventDefault();
    e.stopPropagation();

    auth.signout().then(user => {
      //router.push('/admin/login')
      router.push('/');
    }).catch(error => {
      console.log(error)
    })
  }

  if(checkAuth){
    return(
      
      <div>
        <h1 className="logged-in-text"> You are already logged in </h1>
        <button className="btn btn-primary btn-sm" onClick={(e) => signout(e)}>Logout</button>
      </div>
      
      
    )
  }

  return(
    <Fragment>
      <h1> Trade Login </h1>
      <div className='loading-panel-holder'>
        <div className='panel-content'>
          <form method='POST' noValidate='' onSubmit={submit}>
            <div className='form-group'>
              <label htmlFor='email'>Email</label>
              <input
                id='email'
                type='email'
                className='form-control'
                name='email'
                required
                autoFocus
                autoComplete='false'
                onChange={(event) => {
                  setUsername(event.target.value);
                }}
              />
              <div className='invalid-feedback'>Email is invalid</div>
            </div>
            <div className='form-group'>
              <label htmlFor='password'>Password</label>
              <input
                id='password'
                type='password'
                className='form-control'
                name='password'
                required
                autoComplete='false'
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />
              <div className='invalid-feedback'>Password is required</div>
            </div>
            <div className='form-group m-0'>
              <button type='submit' disabled={pending} className='btn btn-primary btn-block'>
                {pending && <span className='spinner-grow spinner-grow-sm' role='status' aria-hidden='true'></span>}
                <span className=''>Log In</span>
              </button>
            </div>
            {error && <div style={{ color: 'red' }}>{error}</div>}
          </form>
          <div style={{ marginTop: 20 }}>
            Don't have an account? <SiteLink to={'/member/signup'}> Apply Here </SiteLink>
          </div>
          <div style={{ marginTop: 20 }}>
            <SiteLink to={'/member/forgotPassword'}>Forgot Password?</SiteLink>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
