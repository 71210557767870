const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',
  primaryColor: '#00577d',
  fontColor: '#404040',
  bgColor: '#ebebeb',
  greyColor : '#444444',
  lightGreyColor : '#ebebeb',
  darkTealColor : '#00577d',
  lightTealColor : '#76b1c2',
  darkGrayColor : '#6d6c68',
  ...overloading
}

export default variables;