/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './errorMessageStyle';

export function ErrorMessage({ errors }){

    console.log("ErrorMessage Start");

    if (!errors) return null;
    if (typeof errors.length !== 'undefined' && errors.length === 0) return null;

    // if (summaryMessage) return <div className="error" role="alert">{summaryMessage}</div>

    let message = errors;
    if (Array.isArray(errors)) {
        if (errors.length === 0) return null;
        message = errors.map((error, index) => <div key={index}>{error.errorMessage}</div>);
    }

    return(
        <div css={style.error} className="error" role="alert">{message}</div>
    )

}