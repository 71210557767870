import { mq, variables } from 'cssInJs';

export default {
  button : {
      borderRadius: '5px',
      width: '100%',
      lineHeight: '1',
      fontSize: '0.8rem',
      '&.selected' : {
        backgroundColor: variables.lightTealColor
      }
  },
  col : mq({
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    paddingLeft: ['5px', null, '15px'],
    paddingRight: ['5px', null, '15px'],
    cursor: 'pointer',
    '&.selected' : {
      pointerEvents: 'none'
    }
    //border: '1px solid black',
    //textAlign: 'center',
    //padding: '1px'
  }),
  // inCol : mq({
  //   border: '1px solid black',
  //   cursor: 'pointer',
  //   height: '72px',
  //   position: 'relative',
  //   '&.selected' : {
  //     border: '2px solid #003c57',
  //     pointerEvents: 'none'
  //   }
  // }),
  qty : {
    marginLeft: '0.2rem'
  },
  row : {
    marginLeft: '0px',
    marginRight: '0px'
  },
  logoImage : {
    width:'120px'
  },
  brandName : {
    position: 'absolute',
    left: '50%',
    top:'50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: '600',
    lineHeight: '1',
    fontSize: '0.7rem'
  },
  bg : {
    // padding-top: 70px;
    // background-position: center;
    // background-size: cover;
    // background-repeat: no-repeat;
    paddingTop:'70px',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    marginBottom: '0.1rem'
  }
}