/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useState } from 'react';
import style from './historyViewStyle';
import { AiOutlineCloseCircle } from "react-icons/ai";
import config from 'config';
import env from 'env';
import { ErrorMessage } from '../errorMessage/errorMessage';
import { Loading } from 'components';


function GetHistoryOrder({historyOrders, confirmReOrder}){

    const [historyOrdersStatus, setHistoryOrdersStatus] = useState({
        historyOrders : historyOrders
    });

    const getDateFormat = ((dateItem) => {
        const _date = new Date(dateItem);
        return _date.getDate() + "-" + (_date.getMonth() + 1) + "-"  + _date.getFullYear()
    });

    const openItem = ((index) => {
        // const preItem = document.querySelector("#" + id);
        // const preItemClass = JSON.parse(JSON.stringify(document.querySelector("#" + id).classList.toString()));

        // const _items = document.querySelectorAll('.ho-items');
        // _items.forEach(el => {
        //     el.classList.remove("open");
        //     el.classList.add("close");
        // });

        // if(preItemClass.toString().includes("open")){
        //     preItem.classList.remove("open");
        //     preItem.classList.add("close");
        // }
        // else if(preItemClass.toString().includes("close")){
        //     preItem.classList.remove("close");
        //     preItem.classList.add("open");
        // }
        
        setHistoryOrdersStatus(pre => {
            let _new = {...pre};
            const templeListOpen = JSON.parse(JSON.stringify(_new.historyOrders[index].listOpen));
            _new.historyOrders.forEach(el => {
                el.listOpen = false;
            });
            if(templeListOpen === false){
                _new.historyOrders[index].listOpen = true;
            }
            return _new;
        });
    });
    
    const _historyOrders = historyOrdersStatus.historyOrders.map((item, index) =>
        <div key={index} css = {style.container}>
        <div css = {style.item}>
            <div className = "itemin">
                {'Order No : ' + item.orderNo}
            </div>
            <div className = "itemin">
                {'Location : ' + item.location}
            </div>
            <div className = "itemin">
                {'Order Date : ' + getDateFormat(item.dateCreated)}
            </div>
            <div className = "itemin">
                {'Total QTY : ' + item.totalQty}
            </div>
            <div className = "itemin">
                <button style={{lineHeight: '1', padding: '0.2rem'}} className={`btn btn-primary`} onClick={()=>{
                    // openItem('hoitems' + index );
                    openItem(index);
                }}> { !item.listOpen ? 'OPEN' : 'CLOSE'} </button>
                <button style={{lineHeight: '1', padding: '0.2rem', marginLeft:'0.2rem'}} className={`btn btn-primary`} onClick={() => {
                    confirmReOrder(item);
                }}>
                     REORDER
                </button>
            </div>
        </div>
        <div css={style.scontainer} id={'hoitems' + index} className={`ho-items ${item.listOpen === true ? 'open' : 'close'}`}>
            {
                item.items.map((sitem, sindex) =>
                <div key={sindex} css = {style.sitem}>
                    <div className = "sitemin"> { 'Part Number : ' + sitem.partNumber} </div>
                    <div className = "sitemin"> { 'QTY : ' + sitem.qty} </div>
                </div>)
            }
        </div>
        </div>
    );

    return(
        <div>
            {
                _historyOrders
            }
        </div>
    )
}

export function HistoryView({closePopUp, confirmReOrder}){
    console.log("HistoryView");
    const [hoStatus, setHoStatus] = useState({
        errors : [],
        historyOrders : null,
        pagePending : true
    });

    console.log("2csd", hoStatus);

    useEffect(()=>{
        const fetchData = async() => {
            let _errors = [];
            let _historyOrders = null;
            try{
                const _options = { headers: { 'Content-Type': 'application/json' } };
                await config.apiNetJwt(_options);
                const res = await fetch(env.apiBase + '/api/purchaseorder/GetHistoryOrder', {
                    method : 'get',
                    ..._options
                });
                const data = await res.json();
                console.log("data", data);
                
                if(data.results.historyOrders && data.errors.length <= 0){
                    //_historyOrders = data.results.historyOrders
                    _historyOrders = [];
                    data.results.historyOrders.forEach(el => {
                        _historyOrders.push({...el, listOpen : false});
                    });
                }
                
                //let aaa = JSON.parse(data.results.historyOrder[0].itemSerialized);
                //console.log(aaa);

                if(data.errors.length > 0){
                    _errors = data.errors;
                }

            }
            catch(ex){}
            
            setHoStatus({
                ...hoStatus,
                historyOrders : _historyOrders,
                errors : _errors,
                pagePending : false
            });
        }
        fetchData();
        // eslint-disable-next-line
    }, []);

    return(
        <Fragment>
            <div css={style.portal} onClick={closePopUp}></div>
            <div css = {style.portalItem}> 
                {
                    hoStatus.pagePending ? <Loading></Loading> :
                    <Fragment>
                        <div css = {style.top}>
                            <AiOutlineCloseCircle onClick={closePopUp} />
                        </div>
                        {
                            (hoStatus.historyOrders && hoStatus.historyOrders.length > 0) ?
                            <GetHistoryOrder historyOrders = {hoStatus.historyOrders}  confirmReOrder = {confirmReOrder} /> : <h1> No Products </h1>
                        }
                    </Fragment>
                }
            </div>
            <ErrorMessage errors={hoStatus.errors} />
        </Fragment>
    )
}