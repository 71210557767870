import { fb, validators } from '../../../form';

export function getShippingFormData(cart, callType = "normal") {
  
  const data = cart || {};

  const shippingMethods = [
    { name: 'Shipping', value: "Standard" },
    { name: 'Pickup', value: "Pickup" }
  ];

  const model = fb.group({
    billingInfo: address(data.billingAddress, callType),
    shippingInfo: address(data.shippingAddress, "normal"),
    //shippingMethod: [data.shippingMethod || '', [], { label: 'Shipping Method', type: 'text' }],
    shippingMethod: [data.shippingMethod || '', [], { label: null, type: 'radioList', options : shippingMethods, style : {inline : true} }],
    useSameAddress: [data.useSameAddress || false, [], { label: `Use the same address`, type: 'checkbox' }]
  });
  return model;
}

export function address(model, callType) {
  const data = model || {};

  const locations = [
    { name: 'State*', value: "" },
    { name: 'SA', value: "sa" },
    { name: 'WA', value: "wa" },
    { name: 'NT', value: "nt" },
    { name: 'QLD', value: "qld" },
    { name: 'VIC', value: "vic" },
    { name: 'TAS', value: "tas" },
    { name: 'NSW', value: "nsw" },
    { name: 'ACT', value: "act" }
  ];

  const locations2 = [
    { name: 'State*', value: "" },
    { name: 'SA', value: "sa" },
    { name: 'WA', value: "wa" },
    { name: 'NT', value: "nt" },
    //{ name: 'QLD', value: "qld" },
    { name: 'VIC', value: "vic" },
    { name: 'TAS', value: "tas" }
    //{ name: 'NSW', value: "nsw" },
    //{ name: 'ACT', value: "act" }
  ];

  return fb.group({
    firstName: [data.firstName || '', [validators.Required()], { label: 'First Name', type: 'text' }],
    surname: [data.surname || '', [validators.Required()], { label: 'Surname', type: 'text' }],
    street1: [data.street1 || '', [validators.Required()], { label: 'Street', type: 'text' }],
    street2: [data.street2 || '', [], { label: null, type: 'text' }],
    suburb: [data.suburb || '', [validators.Required()], { label: 'Suburb', type: 'text' }],
    //state: [data.state || '', [validators.Required()], { label: 'State', type: 'text' }],
    state: [data.state || '', [validators.Required()], { label: 'State', type: 'select', options : callType === 'normal' ? locations : locations2 }],
    postcode: [data.postcode || '', [validators.Required()], { label: 'Postcode', type: 'text' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email Address', type: 'email' }],
    mobile: [data.mobile || '', [validators.Required()], { label: 'Mobile', type: 'tel' }],
  })
}

// public AddressModel ShippingInfo { get; set; }
// public AddressModel BillingInfo { get; set; }
// public string ShippingMethod { get; set; }
// public bool UseSameAddress { get; set; }
// public string SpecialRequest { get; set; }

// public string FirstName { get; set; }
// public string Surname { get; set; }
// public string AddressLine { get; set; }
// public string Suburb { get; set; }
// public string State { get; set; }
// public string Postcode { get; set; }
// public string Country { get; set; }
// public string CountryCode { get; set; }
// public string Email { get; set; }
// public string Phone { get; set; }
// public string Mobile { get; set; }