/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './addCartPopUpStyle';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Fragment } from 'react';

export function AddCartPopUp({ closePopUp, currentCartProducts, confirmCartOreder }){
    console.log("AddCartPopUp", currentCartProducts);
    const _currentCartProductsFiltered = currentCartProducts.map((item, index) => 
        <div key={index} css = {style.savedProduct}>
            <div className = "savedProducts__item">
                <img css={style.img} src = "/assets/logos/logo.png" alt = "product" />
            </div>
            <div className = "savedProducts__item"> { 'Brand : ' + item.brand} </div>
            {
                item.category !== 'NO' &&
                <div className = "savedProducts__item"> { 'Category : ' + item.category} </div>
            }
            <div className = "savedProducts__item"> { 'Part Number : ' + item.partNumber} </div>
            {
                (item.voltage && item.voltage !== '') &&
                <div className = "savedProducts__item"> { 'Voltage: ' + item.voltage} </div>
            }
            {
                (item.capacity && item.capacity !== '') &&
                <div className = "savedProducts__item"> { 'Capacity: ' + item.capacity} </div>
            }
            <div className = "savedProducts__item" css = {style.qty}> { 'QTY : ' + item.qty } </div>
        </div>
    );

    return(
        <div css={style.portal} onClick={closePopUp}>
            <div css = {style.portalItem}>
                <div css = {style.top}>
                    <AiOutlineCloseCircle onClick={closePopUp} />
                </div>
                {
                    _currentCartProductsFiltered.length > 0 ?
                    <Fragment>
                        <div css = {style.middle}>      
                            {_currentCartProductsFiltered}
                        </div>
                        <div css = {style.bottom}>
                            <button className={`btn btn-primary`} onClick={()=> {
                                confirmCartOreder()
                            }}> CONFIRM </button>
                        </div>
                    </Fragment> :
                    <h1> No Products </h1>
                }
            </div>
        </div>
    )
}