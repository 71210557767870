/** @jsx jsx */
import { jsx } from '@emotion/core'
import cmsUtils from '../../cmsUtils';
import { Item } from '../../../../cms/items/item';
import utils from '../../../../utils';
import { getPanelStyle } from './panelStyle';
import { SiteLink } from 'components';

export function Panel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  
  //console.log(cmsUtils.hasItemInPageDataByItemTypeCode(cmsOption.pageData, 'DestinationOperators'))

  const bgColor = cmsUtils.payload(item, 'BgColor');
  //const textColorTheme = cmsUtils.payload(item, 'TextColorTheme');
  const imageUrl = cmsUtils.payload(item, 'ImageUrl');
  const backgroundTile = cmsUtils.payload(item, 'BackgroundTile');
  const bgTheme = cmsUtils.payload(item, 'TextColorTheme') || 'white';
  //console.log(bgTheme)

  let bgUrl = null;
  if (imageUrl)
    bgUrl = utils.site.resourcePath(imageUrl);
  else if (backgroundTile)
    bgUrl = utils.site.resourcePath(`/dist/transparent-textures/textures/${backgroundTile}`);

  const cssClass = utils.classNames('cms_item', 'contentPanel', 'contentPanel--' + bgTheme, item.cssClass || item.anchorName || '');

  const subItemsElts = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );

  const s = getPanelStyle();

  function onMounse(){
    document.getElementById("trade").classList.add("hover");
  }

  function offMounse(){
    document.getElementById("trade").classList.remove("hover");
  }

  return (
    <section className={cssClass} style={{
      backgroundColor: `${bgColor}`,
      backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl)
    }} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <div className="contentPanel__bg"></div>
      <div className="container">{subItemsElts}</div>
      {
        (item.anchorName && item.anchorName === 'trade') &&
        <SiteLink css={s.trade} to='/trade' onMouseEnter={onMounse} onMouseLeave = {offMounse}>
          <div></div>
        </SiteLink>
      }
    </section>
  );
}
