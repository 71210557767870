export default{
    portal : {
        backgroundColor:'rgba(0,0,0,0.3)',
        position: 'fixed',
        top:'0px',
        left:'0px',
        width:'100%',
        height: '100%',
        zIndex:'9998',
    },
    orderResult : {
        //position: 'absolute',
        position: 'fixed',
        //width: '100%',
        //maxWidth: '900px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        //paddingTop: '2rem',
        padding: '1rem',
        margin: '0px',
        boxShadow: '5px 6px 3px #ebebeb',
        zIndex:'9999',
    },
    savedProduct : {
        display: 'flex',
        flex: '1 0 auto',
        border: '1px solid #ebebeb',
        padding: '0.5rem',
        //padding: '0.5rem 0px',
        //margin:'0.5rem 0px',
        //border: '1px solid #ebebeb',
        '& .savedProducts__item' : {
            margin:'0px 0.5rem',
            marginTop: 'auto',
            marginBottom:'auto'
        }
    },
    main:{
        display: 'flex',
        padding: '0.5rem 0px',
        margin:'0.5rem 0px',
    },
    img : {
        width: '60px'
    },
    qty : {
        flex: '1 0 auto',
        textAlign: 'right',
        fontWeight: '700'
    },
    top : {
        'svg' : {
            fontSize: '1.5rem',
            cursor: 'pointer',
            position: 'absolute',
            right: '0px',
            top:'50%',
            transform:'translateY(-50%)'
        },
        textAlign: 'right'
    },
    middle : {
        maxHeight: '900px',
        overflow: 'auto'
    },
    bottom : {
        textAlign: 'center'
    },
    input : {
        marginBottom: '0px !important',
        maxWidth: '80px',
        display: 'inline-block'
    },
    removeBtn : {
        display: 'inline-block',
        marginLeft: '0.2rem',
        marginRight: '0.2rem',
        'svg' : {
            lineHeight: '1',
            fontSize: '1.5rem',
            cursor: 'pointer'
        }
    }
}