//import { css } from '@emotion/core';
import { mq } from '../../cssInJs';

export default{
    
    container : mq({
        cursor: 'pointer',
        zIndex: '1000',
        position: 'fixed',
        padding: '0',
        right: ['200px'],
        display: ['none','block']
        //border: '1px solid red'
    }),
    flexContainer : {
        display: 'flex',
        transition: 'all 0.5s ease-in-out',
        '&.open' : mq({    
            //backgroundColor: 'rgba(255,255,255,0.7)',
            backgroundColor: ['unset',null,null,null,'rgba(255,255,255,0.7)']
        }),
    },
    buttonContainer : mq({

        width : ['40px',null,'50px'],
        height : ['40px',null,'50px'],

        color: 'white',
        display: 'flex',
        alignItems: 'center',
        zIndex: 2,
        'svg' : {
            width:'80%',
            height:'80%'
        }
    }),

    form  : mq({
        //backgroundColor: 'rgba(255,255,255,0.7)',
        paddingLeft: '10px',
        paddingRight: '10px',
        transition: 'all 0.5s ease-in-out',
        maxWidth: '0px',
        opacity: 0,
        display: 'none',
        '&.open' : {
            maxWidth: '700px',
            opacity: 1,
            display: ['none',null,null,null,'block'],
            width:['0px',null,null,null,'300px', '380px'],
        }
    }),

    formForMobile :mq({
        position: 'absolute',
        top:'60px',
        right:'0px',
        display: 'none',
        backgroundColor: 'white',
        height:'50px',
        '&.open' : {
            maxWidth: '700px',
            opacity: 1,
            display: ['block',null,null,null,'none'],
            width:['280px','300px',null,'380px','0px'],
        }
    }),

    input : {
        height: '100%',
        width:'100%',
        border: 'none',
        outline: 'none',
        backgroundColor: 'transparent',
        '&::placeholder' : {
            color:'black',
            fontWeight: 600
        }
    }
}