import { css } from '@emotion/core';
import { mq } from '../../cssInJs';
export default {
  button: css(mq({
    position: 'absolute',
    left: '1rem',
    lineHeight: '1.6rem',
    color: 'white',
    fontSize: ['20px', null, '24px']
  })),
}