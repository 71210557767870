/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './brandListStyle';
import utils from '../../../../utils';

export function BrandList({changeCurrnetProduct, currentInfo, brands}){
    console.log("Start ProductList", currentInfo);
    const buttons = brands.map((item, index) =>
        <div css={style.col} className={`col-6 col-md-3 ${currentInfo === item.brandName ? 'selected' : '' }`} key={index} onClick = {() => changeCurrnetProduct(item.brandName)}>
            <div css={style.bg} style={{backgroundImage:utils.css.bgUrlStyle(item.logoImage)}}></div>
            <button css={style.button} className={`btn btn-primary ${currentInfo === item.brandName ? 'selected' : '' }`}>
                { item.brandName } 
                {/* {
                    item.totalQty > 0 &&
                    <span css={style.qty}>{ '(' + item.totalQty + ')' }</span>
                } */}
            </button>
        </div>
    );

    return(
        <div css = {style.row} className="product-list-container row">
            { buttons }
        </div>
    )
}

//disabled = {currentInfo === item.name}