import React from 'react';
import { Route } from 'react-router-dom';

//import { AppHeader } from 'layout/appHeader';
import { AppSideBar } from 'layout/appSideBar';

const DashboardLayout = ({ children, ...rest }) => {

  return (
    <div className="app-container app-theme-white fixed-header fixed-sidebar fixed-footer">
      {/* <AppHeader /> */}
      <div className="appMain">
        <AppSideBar />
        <div className="appMain__outer">
          <div className="appMain__inner">
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

const DashboardLayoutRoute = ({ component: RenderComponent, ...rest }) => {
  return (
    <Route {...rest} render={matchProps => (
      <DashboardLayout>
        <RenderComponent {...matchProps} />
      </DashboardLayout>
    )} />
  )
};

export default DashboardLayoutRoute;