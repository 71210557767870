import { variables } from "../../../../cssInJs";


export function getFormStyle(){
    return{
        form : {
            maxWidth: '960px',
            margin: 'auto'
        },
        batteryMatcherForm : {
            //color: 'red'
            'ul' : {
                listStyle: 'none',
                display: 'flex',
                maxWidth: '516px',
                paddingLeft: '0px'
            },
            'li' : {
                flex: '1 0 auto',
                'button.selected' : {
                    background: variables.darkTealColor,
                    color: 'white'
                }
            },
            '.col-form-label' : {
                textAlign: 'right'
            }
        }
    }
}