import { css } from '@emotion/core';
import { variables, mq } from '../../../cssInJs';

const numberOfTiles = [1, 2, 3, 3]
//const marginLR = [14, 14, 14, 14]
const marginTB = [14, 14, 14, 14]

const widthPercent = (index) => 100 / numberOfTiles[index] + '%'

const linkText = {
  color: variables.fontColor,
  fontSize: '0.8rem',
  //padding: '0 20px',
  // '&:hover': {
  //   color: variables.fontColor,
  // }
}

export const tileListStyle = {
  tiles: mq({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    //marginLeft: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    //marginRight: [-marginLR[0] / 2, -marginLR[1] / 2, -marginLR[2] / 2, -marginLR[3] / 2],
    marginTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    marginBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    marginLeft: '-15px',
    marginRight: '-15px'
    //maxWidth:['960px'],
    //margin: 'auto'
  }),
  categoryList : mq({
    marginTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    marginBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    //maxWidth:['960px'],
    //margin: 'auto',
    'div.ff.form-group' : {
      maxWidth: '250px'
    }
  }),
  container : mq({
    maxWidth: '960px',
    margin: 'auto'
  })
}

export const tileStyle = {
  tile: mq({
    //paddingLeft: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    //paddingRight: [marginLR[0] / 2, marginLR[1] / 2, marginLR[2] / 2, marginLR[3] / 2],
    paddingLeft : '15px',
    paddingRight : '15px',
    paddingTop: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    paddingBottom: [marginTB[0] / 2, marginTB[1] / 2, marginTB[2] / 2, marginTB[3] / 2],
    maxWidth: [widthPercent(0), widthPercent(1), widthPercent(2), widthPercent(3)],
    flexBasis: [widthPercent(0), widthPercent(1), widthPercent(2), widthPercent(3)],
    flexGrow: 0,
    flexShrink: 0
  }),
  tileLink: {
    background: '#fff',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    //padding: 5,
    //border: '1px solid red',
    '&:hover': {
      textDecoration: 'none',
      //background: '#f3f3f3'
      '& .buyNowBtn' : {
        backgroundColor: '#006892',
        color: 'white'
      },
      '& .bgImage' : {
        transform: 'scale(1.02)'
      }
    }
  },
  prdImage: {
    //padding: '0 5px',
    //maxWidth: '190px',
    //margin: 'auto',
    //width: '100%',
    backgroundColor: 'grey',
    //overflow: 'hidden'
  },
  bg: {
    //height: 0,
    //paddingBottom: '100%',
    paddingTop: '210px',
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    transition: 'all .2s ease-in-out'
  },
  shortDes : css({
    //textAlign:'center',
    //marginTop: '0.8rem',
    fontSize:'0.7rem',
    marginTop: '0.5rem',
    marginBottom : '0.5rem',
    color : variables.fontColor,
    lineHeight: '1'
  }),
  name: css(linkText, {
    fontWeight: 'bold',
    fontFamily: variables.familyHeader,
    //textAlign:'center',
    lineHeight: '1',
    //marginTop: '0.5rem',
    //color:variables.primaryColor,
    color: '#64b3c4',
    fontSize:'1.14rem'
  }),
  price: css(linkText, {
    //marginTop: 'auto',
    //textAlign:'center',
    //marginBottom:'1rem',
    fontWeight:'700',
    fontSize:'0.8rem',
    flex:'1 0 auto'
  }),
  desContainer : {
    backgroundColor: '#ebebeb',
    padding: '0.8rem',
    flexDirection: 'column',
    height: '100%',
    display: 'flex',
    marginTop: '0.5rem'
  },
  buyNowButton : {
    border: '1px solid #006892',
    textAlign: 'center',
    marginTop: '0.5rem',
    paddingTop: '0.1rem',
    paddingBottom:'0.1rem'
  }
}