import { fb, validators } from '../../../lib/form';

export function getContactModel(data) {
  
  //let stateList = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'].map(x => { return { name: x, value: x } })
  let stateList = [
    {name: 'What state are you from?', value: ''},
    {name: 'Australian Capital Territory', value: 'ACT'},
    {name: 'New South Wales', value: 'New South Wales'},
    {name: 'Victoria', value: 'Victoria'},
    {name: 'South Australia', value: 'South Australia'},
    {name: 'Queensland', value: 'Queensland'},
    {name: 'Western Australia', value: 'Western Australia'},
    {name: 'Northern Territory', value: 'Northern Territory'},
    {name: 'Tasmania', value: 'Tasmania'}
  ]
  
  const model = fb.group({
    name: [data.name || '', [validators.Required()], { label: 'Name', type: 'text' }],
    phone: [data.phone || '', [validators.Required()], { label: 'Phone', type: 'text' }],
    email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email', type: 'email' }],
    enquiry: [data.enquiry || '', [validators.Required()], { label: 'Your Message', type: 'textarea' }],
    attachments: [data.attachments || '', [], { label: 'Attachments', type: 'file' }],
    state : [data.state || '', [validators.Required()], { label: null, type: 'select', options: stateList }],
  }); 
  return model;
}