/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './summaryStyle';

import utils from '../../../utils';
import { Fragment } from 'react';

export function CartSummary({ cart }) {
  const formatMoney = utils.money.formatMoney;
  return <div css={style.summary}>
    {
      (cart.shippingMethod === "Standard" && cart.shippingAmount !== 0 )  &&
      <Fragment>
        <div css={style.totalHdr}> Shipping </div>
        <div css={style.totalAmount}> AU {formatMoney(cart.shippingAmount)} </div>
        <hr style={{borderTop:'1px solid white'}} />
      </Fragment>
    }
    <div css={style.totalHdr}> Total Amount </div>
    <div css={style.totalAmount}> AU {formatMoney(cart.totalAmount)} </div>
  </div>
}