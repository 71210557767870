export default{
    portal : {
        backgroundColor:'rgba(0,0,0,0.3)',
        position: 'fixed',
        top:'0px',
        left:'0px',
        width:'100%',
        height: '100%',
        zIndex:'9998',
    },
    result : {
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '1rem',
        margin: '0px',
        boxShadow: '5px 6px 3px #ebebeb',
        zIndex:'9999'
    },
    top : {
        'svg' : {
            fontSize: '1.5rem',
            cursor: 'pointer'
        },
        textAlign: 'right'
    },
}