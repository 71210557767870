import React from 'react';
//import { Route, Redirect } from 'react-router-dom';
//import DashboardNoAuthLayoutRoute from 'layout/dashboardNoAuthLayout';
import DashboardLayoutRoute from 'layout/DashboardLayout';
import { OrderView } from '../site/purchaseOrder/order/orderView';


//import { Login } from 'auth';
//import { useStore } from '../store';

const PurchaseOrderLayout = ({children}) => {
    // const { dispatch } = useStore();
  
    // useLayoutEffect(() => {
    //   dispatch({type:'SET_STATE', payload : {scrolled : true}});
      
    //   // eslint-disable-next-line
    // }, []);
  
    return(
      <div>
        <DashboardLayoutRoute path='/purchase-order/order' component={OrderView} />
      </div>
    )
  };
  export default PurchaseOrderLayout;