/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from 'utils';
import cmsUtils from '../../cmsUtils';
import { BatteryMatcherForm } from './batteryMatcherForm';
import { getFormStyle } from './formStyle';
//import { useRef } from 'react';

export function Form(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'form', item.cssClass || item.anchorName || '');
    const formSelect = cmsUtils.payload(item, 'formSelect') || 'return';
    const s = getFormStyle();
    
    return(
        <div css = {s.form} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            {
                formSelect === 'battery' && <BatteryMatcherForm cssStyle = {s.batteryMatcherForm}></BatteryMatcherForm>
            }
            {
                formSelect === 'return' && <BatteryMatcherForm></BatteryMatcherForm>
            }
        </div>
    )
}