/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './orderViewStyle';
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
import { Fragment, useState } from 'react';

export function OrderView({ closePopUp, savedProducts, confirmOrder, changeSavedProductQty, removeSavedProduct}){
    
    console.log("Order view start", savedProducts);

    //const savedProductsFiltered = savedProducts.filter(x => x.qty !== '' && x.qty !== '0');

    savedProducts.sort((a, b) => {
        var brandA = a.brand.toUpperCase();
        var brandB = b.brand.toUpperCase();
        if(brandA < brandB){
            return -1;
        }
        if(brandA > brandB){
            return 1;
        }
        return 0;
    });

    const [location, setLocation] = useState();
    const [purchaseOrderNum, setPurchaseOrderNum] = useState("");
    const [quoteRequested, setQuoteRequested] = useState("No");
    const [comments, setComments] = useState();

    function handChange(el){
        const elName = el.target.name;
        const elValue = el.target.value;
        if(elName === 'location'){
            setLocation(elValue);
        }
        else if(elName === 'purchaseOrderNum'){
            setPurchaseOrderNum(elValue);
        }
        else if(elName === 'quoteRequested'){
            setQuoteRequested(elValue);
        }
        else if(elName === 'comments'){
            setComments(elValue);
        }
        else{
            changeSavedProductQty({partNumber : elName, qty : elValue});
        }
    }

    const blurChange = ((el) => {
        const elName = el.target.name;
        const elValue = el.target.value;
        if(elValue === ""){
            changeSavedProductQty({partNumber : elName, qty : "1"});
        }
    });

    const _savedProducts = savedProducts.map((item, index) =>
        <div key={index} css={style.main}>
            <div css = {style.savedProduct} > 
            <div className = "savedProducts__item">
                <img css={style.img} src = "/assets/logos/logo.png" alt = "product" />
            </div>
            <div className = "savedProducts__item"> { 'Brand : ' + item.brand} </div>
            {
                item.category !== 'NO' &&
                <div className = "savedProducts__item"> { 'Category : ' + item.category} </div>
            }
            <div className = "savedProducts__item"> { 'Part Number : ' + item.partNumber} </div>
            {
                (item.voltage && item.voltage !== '') &&
                <div className = "savedProducts__item"> { 'Voltage: ' + item.voltage} </div>
            }
            {
                (item.capacity && item.capacity !== '') &&
                <div className = "savedProducts__item"> { 'Capacity: ' + item.capacity} </div>
            }

            <div css={style.input} className="form-group savedProducts__item">
                <input type="number" className="form-control form-control-sm" min = "1" onChange = { handChange } onBlur = {blurChange} name = {item.partNumber} value={ item.qty } />
            </div>
            <div css={style.removeBtn} className = "savedProducts__item">
                <AiFillCloseCircle onClick={() => {
                    removeSavedProduct(index)
                }} />
            </div> 
            
            </div>
            
        </div>
    );
    
    return(   
        <Fragment>
            <div css={style.portal} onClick={closePopUp}></div>
            <div css = {style.orderResult}>
                <div css = {style.top}> 
                
                <div className='form-group row'> 
                    {
                        _savedProducts.length > 0 &&
                        <div className = "col-10">
                        <select id="location" name="location" className="form-control" required value={location} onChange={handChange}>
                            {/* <option value=""> What state are you from?  </option> */}
                            <option value=""> Please choose location?  </option>
                            <option value = "act"> Australian Captital Territory</option>
                            <option value = "nsw"> New South Wales </option>
                            <option value = "vic"> Victoria </option>
                            <option value = "sa"> South Australia </option>
                            <option value = "qld"> Queensland </option>
                            <option value = "wa"> Western Australia </option>
                            <option value = "nt"> Northern Territory </option>
                            <option value = "tas"> Tasmania </option>
                        </select>
                        </div>
                    }

                    <div className={`${_savedProducts.length > 0 ? 'col-2' : 'col-12' }`}>
                        <AiOutlineCloseCircle onClick={closePopUp} />
                    </div>
                    <div className='invalid-feedback'>Location is invalid</div>
                    </div>
                </div>
                {
                    _savedProducts.length > 0 ?
                    <Fragment>
                        <div css = {style.middle}>      
                            {_savedProducts}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='purchaseOrderNum'> Purchase Order # </label>
                            <input id='purchaseOrderNum' type='text' className='form-control' value={purchaseOrderNum} name='purchaseOrderNum' autoComplete='false' onChange={handChange} />
                            <div className='invalid-feedback'> Purchase Order is invalid </div>
                        </div>

                        <div className='form-group'>
                            <label style={{marginRight:'2rem'}}> QUOTE REQUESTED </label>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="quoteRequested"id="quoteRequested1" value="Yes" checked = {quoteRequested === 'Yes'} onChange={handChange} />
                                <label className="form-check-label" htmlFor="quoteRequested1"> Yes </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="quoteRequested" id="quoteRequested2" value="No" checked = {quoteRequested === 'No'} onChange={handChange} />
                                <label className="form-check-label" htmlFor="quoteRequested2"> No </label>
                            </div>
                        </div>

                        <div className="form-group">
                            <label htmlFor="comments"> Additional Comments </label>
                            <textarea className="form-control" id="comments" name = "comments" rows="2" value={comments} onChange={handChange}></textarea>
                        </div>

                        <div css = {style.bottom}>
                            <button className={`btn btn-primary`} onClick={()=>confirmOrder(location, purchaseOrderNum, quoteRequested, comments)} disabled={!location}> Place Order </button>
                        </div>

                    </Fragment> :
                    <div>
                        <h1> No Products </h1>
                        <p>
                            Your order currently has no products.  Please select the brand to begin your trade order.
                        </p>
                    </div>
                }
            </div>
        </Fragment>
    )
}