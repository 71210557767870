import variables from './cssInJs/variables';
import { getIdToken } from 'lib/auth/services/authHelper';

const cssEmotion = {
  breakpointNames: variables.breakpointNames,
  breakpoints: variables.breakpoints
}






const firebaseSettings = {
  apiKey: "AIzaSyBHYxR9bMY-n1C-N9SWBgIhqlzi06CdqJw",
  authDomain: "batteryspecialties-c1ebe.firebaseapp.com",
  projectId: "batteryspecialties-c1ebe",
  storageBucket: "batteryspecialties-c1ebe.appspot.com",
  messagingSenderId: "115054734574",
  appId: "1:115054734574:web:0e8f396c1d6322bfbc97ff"
};

const apiNetJwt = async (options) => {
  const token = await getIdToken();
  //console.log(token)
  if (token) {
    options.headers = options.headers || {};
    options.headers['Authorization'] = `Bearer ${token}`;
  }
};

export default {
  cssEmotion,
  firebaseSettings,
  apiNetJwt
}